import React from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import CurrencyConverter from "../../../Currency/CurrencyConverter";
import { useSelector } from "react-redux";

const ContestDetailsModal = ({ show, handleClose, contestDetails }) => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const toHistory = () => {
        navigate('/contest-history')
    }
    const conversionFactor = useSelector(
        (state) => state?.user?.conversionFactor
    );
    const userSelectedCurrency = useSelector(
        (state) => state.user?.selectedCurrency
    );
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header>
                {/* <Modal.Title as={"h5"}>{t("contestDetails")}</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
                <ul className="list-group">
                    {Object.entries(contestDetails).map(([key, value]) => (
                        <li key={key} className="list-group-item">
                            <Row>
                                <Col><strong>{t(key)}</strong></Col>
                                <Col>: {(["salesAchieved", "businessVolTarget", "businessVolAchieved"].includes(key))
                                    ? `${userSelectedCurrency?.symbolLeft
                                    } ${CurrencyConverter(
                                        value,
                                        conversionFactor
                                    )}`
                                    : key === "contestType" ? t(value) :
                                        value}
                                </Col>
                            </Row>
                        </li>
                    ))}
                </ul>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={toHistory}>
                    {t("contestDetails")}
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                    {t("close")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ContestDetailsModal;
