import React, { useEffect, useState } from 'react'
import RankClassTable from '../../components/UserRank/RankClassTable'
import { ApiHook } from '../../hooks/apiHook';
import { useTranslation } from 'react-i18next';
import { Card } from 'react-bootstrap'
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
function UserRankClass() {
    const { t } = useTranslation()
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [sortOptions, setSortOptions] = useState({
        sortBy: "",
        direction: ""
    })
    const [clsId, setclsId] = useState()
    const report = ApiHook.CallRankclsHistories(currentPage, itemsPerPage, sortOptions.sortBy, sortOptions.direction, clsId)
    const { data: rankCls } = ApiHook.CallRankClses()
    console.log("=====rankCls===", rankCls)
    useEffect(() => {
        if (rankCls) {
            setclsId(rankCls[0]?.id)
        }
    }, [rankCls])
    console.log("===clsId", clsId)
    const tabSelectHandler = (activeTab) => {
        setclsId(activeTab)
    }
    return (
        <>
            <div className="page_head_top">{t("rank_class_histories")}</div>
            <div className="ewallet_table_section">
                <Card style={{ border: 0 }}>
                    <Card.Body style={{ padding: '2em' }}>
                        {
                            rankCls?.length == 0 ?
                                <div className="nodata-table-view">
                                    <div className="nodata-table-view-box">
                                        <div className="nodata-table-view-box-img">
                                            <img src="/images/no-data-image1.jpg" alt="" />
                                        </div>
                                        <div className="nodata-table-view-box-txt">
                                            {t("sorry_no_data_found")}
                                        </div>
                                    </div>
                                </div>
                                :

                                (rankCls && clsId) &&
                                <Tab.Container id="left-tabs-example"
                                    defaultActiveKey={clsId}
                                    onSelect={tabSelectHandler}
                                >
                                    <Row>
                                        <Col sm={3} className='class-navs'>

                                            <Nav variant="pills" className="flex-column rank-class-nav mt-3">

                                                {

                                                    rankCls.map((item, index) => {
                                                        return (
                                                            <Nav.Item>
                                                                <Nav.Link eventKey={item.id}>
                                                                    <span className='active-star'>
                                                                        <i class="fa-solid fa-star fa-sm"></i>
                                                                    </span>
                                                                    {" "}
                                                                    {item?.name}</Nav.Link>
                                                            </Nav.Item>
                                                        )
                                                    })
                                                }

                                            </Nav>
                                        </Col>
                                        <Col sm={9}>
                                            <Tab.Content >
                                                <Tab.Pane eventKey={clsId} >
                                                    <div className="ewallet_table_section_cnt" style={{ margin: 0 }}>
                                                        <RankClassTable
                                                            data={report.data}
                                                            type={"rank-class-report"}
                                                            setCurrentPage={setCurrentPage}
                                                            currentPage={currentPage}
                                                            itemsPerPage={itemsPerPage}
                                                            setItemsPerPage={setItemsPerPage}
                                                            setSortOptions={setSortOptions}
                                                        />
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                        }



                    </Card.Body>
                </Card>
                {/* <div className="ewallet_table_section_cnt">
                    <RankClassTable
                        data={report.data}
                        type={"rank-class-report"}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        itemsPerPage={itemsPerPage}
                        setItemsPerPage={setItemsPerPage}
                        setSortOptions={setSortOptions}
                    />
                </div> */}
            </div>
        </>

    )
}

export default UserRankClass