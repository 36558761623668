import React from "react";
import TableMain from "../Common/table/TableMain";
import { getLastPage } from "../../utils/getLastPage";
import { useTranslation } from "react-i18next";

const HistoryTable = (props) => {
  const { t } = useTranslation();
  console.log("props?.data? === ", props);
  //   const reportHeader = [t("date"), t("amount"), t("payout_method"), t("status")]; 
  const headers = [
    // t("no."),
    t("type"),
    t("amount"),
    t("description"),
    t("rank"),
    t("salesAchieved"),
    t("recruitsAchieved"),
    t("rsp"),
    t("rrp"),
    t("experienceAdjustment"),
    t("adjustedFps"),
    t("position"),
    t("distributeStatus"),
    t("date")];
  const lastPage = getLastPage(props.itemsPerPage, props?.data?.totalCount);

  return (
    <div className="ewallet_table_section">
      <div className="ewallet_table_section_cnt">
        <div className="table-responsive min-hieght-table">
          <TableMain
            headers={headers}
            data={props.data?.data}
            startPage={1}
            currentPage={props.currentPage}
            totalPages={lastPage}
            type={props.type}
            itemsPerPage={props.itemsPerPage}
            setItemsPerPage={props.setItemsPerPage}
            setCurrentPage={props.setCurrentPage}
          // setEpinSortOptions={props.setSortOptions}
          />
        </div>
      </div>
    </div>
  );
};

export default HistoryTable;
