import { t } from 'i18next'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'

const ContestImagePopup = ({ show, handleClose, url }) => {

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Body> <img src={url} alt="Modal content" style={{ width: '100%', height: 'auto' }} /></Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t("close")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ContestImagePopup