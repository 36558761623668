import React, { useState } from 'react'
import HistoryTable from '../../components/Contest/HistoryTable';
import { ApiHook } from '../../hooks/apiHook';
import { t } from 'i18next';

export default function ContestHistory() {
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const history = ApiHook.CallContestHistory(currentPage, itemsPerPage);
    console.log("history === ", history);

    return (
        <div>{
            <>
                <div className="page_head_top">{t("contestDetails")}</div>
                <HistoryTable
                    itemsPerPage={itemsPerPage}
                    setItemsPerPage={setItemsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    type={"contest"}
                    data={history?.data}
                />
            </>
        }</div>
    )
}
